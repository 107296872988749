<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main">
        <el-form
          :model="form"
          :rules="rules"
          ref="form"
          :inline="true"
          label-position="right"
          :label-width="$config.labelWidth"
        >
          <el-row :gutter="20">
            <el-col :span="24">
              <div class="form-label-base">调度信息</div>
            </el-col>
            <el-col :span="6">
              <el-form-item label="驾驶员" prop="driverId">
                <el-select v-model="form.driverId" placeholder="请选择" filterable>
                  <el-option
                    v-for="(item, idx) in driverList"
                    :key="idx"
                    :label="item.nameType"
                    :value="item.driverId"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="提货车号" prop="plateNumber">
                <el-select v-model="form.plateNumber" placeholder="请选择" filterable>
                  <el-option
                    v-for="(item, idx) in carInfoList"
                    :key="idx"
                    :label="item.plateNumberDes"
                    :value="item.plateNumber"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <div class="details-line"></div>
            </el-col>
            <el-col :span="24">
              <div class="form-label-base">基本信息</div>
            </el-col>
            <el-col :span="6">
              <el-form-item label="订单编号">
                <el-input v-model="form.orderId" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="业务类型">
                <el-input v-model="form.orderType" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="客户名称" prop="partnerId">
                <el-select v-model="form.partnerId" placeholder="请选择" filterable>
                  <el-option
                    v-for="(item, idx) in partnerList"
                    :key="idx"
                    :label="item.partnerShorthand"
                    :value="item.partnerId"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="结算客户" prop="partnerAccountId">
                <el-select v-model="form.partnerAccountId" placeholder="请选择" filterable>
                  <el-option
                    v-for="(item, idx) in partnerList"
                    :key="idx"
                    :label="item.partnerShorthand"
                    :value="item.partnerId"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <div class="details-line"></div>
            </el-col>
            <el-col :span="24">
              <div class="form-label-base">运输信息</div>
            </el-col>
            <el-col :span="6">
              <el-form-item label="运输日期" prop="transportTime">
                <el-date-picker
                  v-model="form.transportTime"
                  clearable
                  type="date"
                  value-format="timestamp"
                  placeholder="选择日期"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item class="empty" label="."></el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item class="empty" label="."></el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item class="empty" label="."></el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="发货人" prop="senderName">
                <el-input v-model="form.senderName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="手机号(发)" prop="senderContactPhone">
                <el-input v-model="form.senderContactPhone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="起运地" prop="adminRegions">
                <el-cascader
                  v-model="form.adminRegions"
                  size="small"
                  :options="regionData"
                  @change="handleRegionChange"
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="详细地址(发)" prop="sendAddressDetail">
                <el-input v-model="form.sendAddressDetail" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="收货人" prop="receiverName">
                <el-input v-model="form.receiverName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="手机号(收)" prop="receiverContactPhone">
                <el-input v-model="form.receiverContactPhone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="目的地" prop="adminRegions_r">
                <el-cascader
                  v-model="form.adminRegions_r"
                  size="small"
                  :options="regionData"
                  @change="handleRegionChangeR"
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="详细地址(收)" prop="receiveAddressDetail">
                <el-input v-model="form.receiveAddressDetail"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <div class="details-line"></div>
            </el-col>
            <el-col :span="24">
              <div class="form-label-base">货物信息</div>
            </el-col>
            <el-col :span="6">
              <el-form-item label="品名" prop="category">
                <el-select v-model="form.category" placeholder="请选择" filterable>
                  <el-option
                    v-for="(item, idx) in  $enums.CompanyList.list"
                    :key="idx"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="吨数" prop="categoryWeight">
                <el-input v-model="form.categoryWeight" type="Number" @change="changeWeight"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
                <el-form-item label="体积(立方米)" prop="volume">
                  <el-input
                    v-model="form.volume"
                    type="number"
                    placeholder="请输入"
                    @blur="validityNumber"
                  ></el-input>
                </el-form-item>
              </el-col>
            <el-col :span="6">
              <el-form-item label="件数" prop="categoryPiece">
                <el-input v-model="form.categoryPiece" type="Number" @blur="validityNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <div class="details-line"></div>
            </el-col>
            <el-col :span="24">
              <div class="form-label-base">费用信息</div>
            </el-col>
            <el-col :span="6">
              <el-form-item label="收入单价(元)" prop="earningUnitPrice">
                <el-input
                  v-model="form.earningUnitPrice"
                  type="Number"
                  @change="changeEarningUnitPrice"
                  @blur="validityNumber"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="收入金额(元)" prop="earningFee">
                <el-input v-model="form.earningFee" type="Number" @blur="validityNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="吊装费(元)" prop="liftingFee">
                <el-input v-model="form.liftingFee" type="Number" @blur="validityNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="过磅费(元)" prop="weightFee">
                <el-input v-model="form.weightFee" type="Number" @blur="validityNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="卸车费(元)" prop="unloadFee">
                <el-input v-model="form.unloadFee" type="Number" @blur="validityNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="成本单价(元)" prop="costUnitPrice">
                <el-input v-model="form.costUnitPrice" type="Number" @change="changeCostUnitPrice"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="成本运费(元)" prop="costCarrierFee" v-if="form.orderType==='外发'">
                <el-input v-model="form.costCarrierFee" type="Number"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="过路过桥费(元)" prop="tollsFee" v-if="form.orderType==='外发'">
                <el-input v-model="form.tollsFee" type="Number" @blur="validityNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="加油费(元)" prop="fuelFee" v-if="form.orderType==='外发'">
                <el-input v-model="form.fuelFee" type="Number" @blur="validityNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="罚款(元)" prop="fine" v-if="form.orderType==='外发'">
                <el-input v-model="form.fine" type="Number" @blur="validityNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="其他费用(元)" prop="otherFee" v-if="form.orderType==='外发'">
                <el-input v-model="form.otherFee" type="Number" @blur="validityNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="提成系数(%)" prop="driverBonusFee" v-if="form.orderType==='外发'">
                <el-input v-model="form.driverBonusFee" type="Number" @blur="validityNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="信息费(元)" prop="messageFee" v-if="form.orderType==='外发'">
                <el-input v-model="form.messageFee" type="Number" @blur="validityNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <div class="details-line"></div>
            </el-col>
            <el-col :span="24">
              <div class="form-label-base">其他信息</div>
            </el-col>
              <el-col :span="24">
                <el-form-item label="下单备注：">
                  <span>{{this.form.cargoShipmentRemark||'- -'}}</span>
                </el-form-item>
              </el-col>

              <el-col :span="6">
                <el-form-item label="订单备注：">
                  <el-input v-model="form.orderRemark" maxlength="64"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item label="交单备注：">
                  <span>{{this.form.deliveryRemark||'- -'}}</span>
                </el-form-item>
              </el-col>
   
              <el-col :span="24">
                <el-form-item label="核算备注：">
                  <span>{{this.form.checkRemark||'- -'}}</span>
                </el-form-item>
              </el-col>
       

            
            <el-col :span="24">
              <div class="details-line"></div>
            </el-col>
          </el-row>
          <div class="form-label-base">
            图片信息
            <el-button class="upload-btn ml15px">
              <i class="el-icon-plus"></i>上传
              <label for="cover_one" class="upload-label">
                <input
                  type="file"
                  hidden
                  @change="OneUploadCover($event)"
                  accept="image/png, image/jpeg"
                  ref="uploadCoverOne"
                  id="cover_one"
                />
              </label>
            </el-button>
          </div>
          <!--图片-->
          <!--图片-->
          <div
            class="order-img"
            :style="{'margin-right': (idx+1)%4===0?'0px':''}"
            v-for="(item, idx) in bigImgList"
            :key="idx"
          >
            <el-image
              :preview-src-list="imgSrcList"
              :src="bigImages[idx]"
              fit="cover"
              style="width: 100%;height: 100%;border-radius: 4px"
              @click="onPreview(idx)"
              lazy
            ></el-image>
            <div class="img-text">
              <div>
                <span>{{item.userName||'- -'}}</span>
                &nbsp;
                <span>{{item.uploaderPhone||'- -'}}</span>
              </div>
              <span>{{item.gmtCreate|str2ymdhm}}</span>
            </div>
            <div class="img-icon">{{item.imageType}}</div>
            <i
              class="el-icon-error close-icon"
              v-if="item.isClear"
              @click="clickClearImg(item,idx)"
            ></i>
          </div>
        </el-form>
      </div>
      <div class="btn-main">
        <!-- <el-button type="success" class="btn-success" @click="clickSubmit('已审核')" :loading="btnLoading">审核通过</el-button> -->
        <el-button type="primary" @click="clickSubmit('保存')" :loading="btnLoading">确认调度</el-button>
        <el-button @click="$router.go(-1)">返 回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { regionData, CodeToText, TextToCode } from "element-china-area-data";
export default {
  data() {
    return {
      // 预览大图
      bigImgList: [],
      bigImages: [],
      imgSrcList: [],
      nav: [
        { name: "外发调度", path: "/outgoing-allot/outgoing-allot" },
        { name: "调度" },
      ],
      partnerList: [],
      driverList: [],
      carInfoList: [],
      zxsArray: ["北京市", "天津市", "上海市", "重庆市"],
      regionData: regionData,
      form: {
        driverId: null,
        plateNumber: null,
        orderId: null,
        orderType: null,
        partnerId: null,
        partnerAccountId: null,
        transportTime: null,
        adminRegions: [],
        adminRegions_r: [],
        sendProvinceName: null,
        sendCityName: null,
        sendCountyName: null,
        sendAddressDetail: null,
        senderName: null,
        senderContactPhone: null,
        receiveProvinceName: null,
        receiveCityName: null,
        receiveCountyName: null,
        receiveAddressDetail: null,
        receiverName: null,
        receiverContactPhone: null,
        category: null,
        categoryWeight: null,
        earningUnitPrice: null,
        earningFee: null,
        costUnitPrice: null,
        costCarrierFee: null,
        categoryPiece: null,
        volume: null,
        liftingFee: null,
        weightFee: null,
        unloadFee: null,
        tollsFee: null,
        fine: null,
        fuelFee: null,
        driverBonusFee: null,
        otherFee: null,
        messageFee: null,
        orderRemark: null,
      },
      rules: {
        sendAddressDetail: {
          required: true,
          message: "请输入详细地址",
          trigger: "blur",
        },
        senderName: {
          required: true,
          message: "请输入发货人",
          trigger: "blur",
        },
        senderContactPhone: {
          required: true,
          message: "请输入发货人手机号",
          trigger: "blur",
        },
        adminRegions_r: {
          required: true,
          message: "请选择目的地",
          trigger: "change",
        },
        receiveAddressDetail: {
          required: true,
          message: "请输入详细地址",
          trigger: "blur",
        },
        receiverName: {
          required: true,
          message: "请输入收货人",
          trigger: "blur",
        },
        receiverContactPhone: {
          required: true,
          message: "请输入收货人手机号",
          trigger: "blur",
        },
        // categoryWeight: {required: true, message: '请输入吨数', trigger: 'blur'},
        // earningUnitPrice: {required: true, message: '请输入收入单价', trigger: 'blur'},
        // costUnitPrice: {required: true, message: '请输入成本单价', trigger: 'blur'},
        // costCarrierFee: {required: true, message: '请输入成本运费', trigger: 'blur'},
        partnerId: {
          required: true,
          message: "请选择客户名称",
          trigger: "change",
        },
        partnerAccountId: {
          required: true,
          message: "请选择结算客户",
          trigger: "change",
        },
        transportTime: {
          required: true,
          message: "请选择运输日期",
          trigger: "change",
        },
        adminRegions: {
          required: true,
          message: "请选择起运地",
          trigger: "change",
        },
        driverId: {
          required: true,
          message: "请选择驾驶员",
          trigger: "change",
        },
        plateNumber: {
          required: true,
          message: "请选择提货车号",
          trigger: "change",
        },
        category: { required: true, message: "请选择品名", trigger: "change" },
        categoryWeight: [
          { required: true, message: "请输入货物重量", trigger: "blur" },
          { validator: this.$validator.isThreeDecimal },
        ],
        categoryPiece: [
          { trigger: "blur" },
          { validator: this.$validator.isInteger },
        ],
        costCarrierFee: [
          { required: true, message: "请输入运费/成本运费", trigger: "blur" },
          { validator: this.$validator.isTwoDecimal },
        ],
        driverBonusFee: [{ validator: this.$validator.isTwoDecimal }],
        carpoolingFee: [
          { required: true, message: "请输入拼车费", trigger: "blur" },
          { validator: this.$validator.isTwoDecimal },
        ],
        liftingFee: [{ validator: this.$validator.isTwoDecimal }],
        earningUnitPrice: [
          { required: true, message: "请输入收入单价", trigger: "blur" },
          { validator: this.$validator.isTwoDecimal },
        ],
        earningFee: [
          { required: true, message: "请输入收入金额", trigger: "blur" },
          { validator: this.$validator.isTwoDecimal },
        ],
        weightFee: [{ validator: this.$validator.isTwoDecimal }],
        unloadFee: [{ validator: this.$validator.isTwoDecimal }],
        tollsFee: [{ validator: this.$validator.isTwoDecimal }],
        fine: [{ validator: this.$validator.isTwoDecimal }],
        fuelFee: [{ validator: this.$validator.isTwoDecimal }],
        otherFee: [{ validator: this.$validator.isTwoDecimal }],
        messageFee: [{ validator: this.$validator.isTwoDecimal }],
        costUnitPrice: [
          { required: true, message: "请输入成本单价", trigger: "blur" },
          { validator: this.$validator.isTwoDecimal },
        ],
      },
      btnLoading: false,
    };
  },
  methods: {
    // 依次查看大图
    onPreview(index) {
      let arr1 = this.bigImages.filter(
        (arrItem, arrIndex) => index <= arrIndex
      );
      let arr2 = this.bigImages.filter((arrItem, arrIndex) => index > arrIndex);
      let arr3 = arr2.sort((a, b) => {
        b - a;
      });
      this.imgSrcList = [...arr1, ...arr3];
    },

    /**
     * 规范数据输入框
     */
    validityNumber(val) {
      this.$utils.validityNumber(
        this,
        val.target.validationMessage.length,
        val.target.value
      );
    },
    /**
     * 变更吨数自动计算
     * 收入金额(元)
     * 成本运费(元)
     */
    changeWeight(val) {
      this.changeEarningUnit(val);
      this.changeCostUnit(val);
    },
    changeEarningUnit(n) {
      if (!this.form.earningUnitPrice) {
        return;
      } else {
        let tempVal = parseFloat(this.form.earningUnitPrice * n).toFixed(3);
        this.form.earningFee = tempVal.substring(0, tempVal.length - 1);
      }
    },
    changeCostUnit(n) {
      if (!this.form.costUnitPrice) {
        return;
      } else {
        let tempVal = parseFloat(this.form.costUnitPrice * n).toFixed(3);
        this.form.costCarrierFee = tempVal.substring(0, tempVal.length - 1);
      }
    },
    /**
     * 计算收入金额
     */
    changeEarningUnitPrice(val) {
      let tempVal = parseFloat(this.form.categoryWeight * val).toFixed(3);
      this.form.earningFee = tempVal.substring(0, tempVal.length - 1);
    },
    /**
     * 计算成本运费
     */
    changeCostUnitPrice(val) {
      let tempVal = parseFloat(this.form.categoryWeight * val).toFixed(3);
      this.form.costCarrierFee = tempVal.substring(0, tempVal.length - 1);
    },
    // 上传图片
    async OneUploadCover(e) {
      if (e.target.files.length === 0) return;
      if (e.target.files[0].size > 1024 * 1024 * 10) {
        this.$message.warning("上传图片不能超过10M");
        return;
      }
      let file = e.target.files[0];
      let formData = new FormData();
      formData.append("file", file);
      formData.append(
        "ailivingToken",
        JSON.parse(sessionStorage.getItem("loginData")).Token
      );
      const fileKey = await this.$api.UploadImg(formData);
      const imageUrl = this.$utils.getObjectURL(file);
      const gmtCreate = this.$dayjs().valueOf();
      this.form.imageInfoVOS.push({
        imageKey: fileKey,
        imageUrl: imageUrl,
        imageType: "补充",
        gmtCreate: gmtCreate,
        isClear: true,
      });
      this.bigImgList.push({
        imageKey: fileKey,
        imageUrl: imageUrl,
        imageType: "补充",
        gmtCreate: gmtCreate,
        isClear: true,
      });
      this.bigImages.push(imageUrl);
      // 不清空input的值，下次选取不到相同的照片
      let oFileInput = document.getElementById("cover_one");
      oFileInput.value = null;
    },
    //  清除照片
    clickClearImg(img, i) {
      this.bigImgList.splice(i, 1);
      this.bigImages.splice(i, 1);
      this.form.imageInfoVOS = this.form.imageInfoVOS.filter((item) => {
        return img.imageKey != item.imageKey;
      });
    },
    // 提交
    clickSubmit(e) {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return;
        this.btnLoading = true;
        const newForm = this.$_.cloneDeep(this.form);
        if (e === "已审核") newForm.orderStatus = "已审核";
        if (e === "保存") newForm.orderStatus = "已调度";
        newForm.imageInfoVOS = [];
        const newImgList = this.form.imageInfoVOS.filter((e) => e.isClear);
        newForm.imageInfoVOS = newImgList.map((e) => {
          return {
            imageUrl: [e.imageKey],
            imageType: e.imageType,
            imageRemark: e.imageRemark,
          };
        });

        /**
         * 处理省市区的差异
         */
        if (newForm.sendCityName === "市辖区") {
          newForm.sendCityName = newForm.sendProvinceName;
        }
        if (newForm.receiveCityName === "市辖区") {
          newForm.receiveCityName = newForm.receiveProvinceName;
        }

        try {
          await this.$api.OrderUpdate({
            type: 2,
            steelOrderInfoVO: newForm,
          });
          this.$notify({
            title: "成功",
            message: `${e === "已审核" ? "操作成功" : "修改成功"}`,
            type: "success",
          });
          this.$router.go(-1);
        } catch (e) {
          // console.log(e);
        }
        this.btnLoading = false;
      });
    },
    /**
     * 起运地省市区
     */
    handleRegionChange(value) {
      var arr = value;
      if (!value) return;
      this.form.sendProvinceName = CodeToText[arr[0]];
      this.form.sendCityName = CodeToText[arr[1]];
      this.form.sendCountyName = CodeToText[arr[2]];
    },
    /**
     * 目的地
     */
    handleRegionChangeR(value) {
      var arr = value;
      if (!value) return;
      this.form.receiveProvinceName = CodeToText[arr[0]];
      this.form.receiveCityName = CodeToText[arr[1]];
      this.form.receiveCountyName = CodeToText[arr[2]];
    },
  },
  watch: {
    "form.adminRegions": function () {
    },
  },
  async created() {
    var pList = [];
    pList = await this.$api.shortList({});
    this.partnerList = pList;

    var cList = [];
    cList = await this.$api.carInfoList({});
    this.carInfoList = cList.data;

    var dList = [];
    dList = await this.$api.driverFastList({});
    this.driverList = dList;

    this.form = await this.$api.OrderGet({
      orderId: this.$route.query.orderId,
    });

    this.form.imageInfoVOS.forEach((item) => {
      if (item.imageUrl.length >= 2) {
        for (let i of item.imageUrl) {
          this.bigImages.push(i);
          this.bigImgList.push({
            gmtCreate: item.gmtCreate,
            gmtModified: item.gmtModified,
            imageRemark: item.imageRemark,
            imageType: item.imageType,
            imageUrl: i,
            orderId: item.orderId,
            tenantId: item.tenantId,
            uploaderPhone: item.uploaderPhone,
            userIdentity: item.userIdentity,
            userName: item.userName,
          });
        }
      } else {
        let img = item.imageUrl[0];
        this.bigImages.push(img);
        this.bigImgList.push(item);
      }
    });

    var provinceCode;
    var cityCode;
    var countyCode;
    var sendProvinceName = this.form.sendProvinceName;

    var receiveProvinceCode;
    var receiveCityCode;
    var receiveCountyCode;
    var receiveProvinceName = this.form.receiveProvinceName;

    try {
      if (this.zxsArray.indexOf(sendProvinceName) !== -1) {
        provinceCode = TextToCode[this.form.sendProvinceName].code;
        cityCode = TextToCode[this.form.sendProvinceName]["市辖区"].code;
        countyCode =
          TextToCode[this.form.sendProvinceName]["市辖区"][
            this.form.sendCountyName
          ].code;
      } else {
        provinceCode = TextToCode[this.form.sendProvinceName].code;
        cityCode =
          TextToCode[this.form.sendProvinceName][this.form.sendCityName].code;
        countyCode =
          TextToCode[this.form.sendProvinceName][this.form.sendCityName][
            this.form.sendCountyName
          ].code;
      }
      this.form.adminRegions = [provinceCode, cityCode, countyCode];
      if (this.zxsArray.indexOf(receiveProvinceName) !== -1) {
        receiveProvinceCode = TextToCode[this.form.receiveProvinceName].code;
        receiveCityCode =
          TextToCode[this.form.receiveProvinceName]["市辖区"].code;
        receiveCountyCode =
          TextToCode[this.form.receiveProvinceName]["市辖区"][
            this.form.receiveCountyName
          ].code;
      } else {
        receiveProvinceCode = TextToCode[this.form.receiveProvinceName].code;
        receiveCityCode =
          TextToCode[this.form.receiveProvinceName][this.form.receiveCityName]
            .code;
        receiveCountyCode =
          TextToCode[this.form.receiveProvinceName][this.form.receiveCityName][
            this.form.receiveCountyName
          ].code;
      }
      this.form.adminRegions_r = [
        receiveProvinceCode,
        receiveCityCode,
        receiveCountyCode,
      ];
    } catch (error) {
      // console.log(error);
    }
  },
  // async mounted () {
  //   this.form = await this.$api.OrderGet({
  //     orderId: this.$route.query.orderId
  //   })
  //   console.log(this.form)
  // }
};
</script>

<style lang='sass' scoped>
.empty /deep/ .el-form-item__label
  color: #fff
   
/deep/ .el-form-item--mini.el-form-item
  display: flex;
  width: 100%;
/deep/ .el-form-item--mini .el-form-item__content
  flex: 1
/deep/ .el-form-item--mini .el-form-item__label
  line-height: 32px
  height: 32px

/deep/ .el-select,
/deep/ .el-cascader--small,
/deep/ .el-date-editor.el-input
  width: 100%
/deep/ .el-input__inner
  height: 28px;
  line-height: 28px;

/deep/ .el-select,
/deep/ .el-cascader--small,
/deep/ .el-date-editor.el-input
  width: 100%
/deep/ .el-input__inner
  height: 28px;
  line-height: 28px;

/deep/ .el-image-viewer__close
  color: #fff
/deep/ .el-image-viewer__next,
/deep/ .el-image-viewer__prev
  border: 3px solid #fff
</style>
